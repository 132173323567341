import { useMemo } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
// helpers
import { selectRegionBasedNavigation } from '@helpers/strapi'
import { getCurrentLocation } from '@helpers/geo-location'

export const StrapiNavigationLinksFragment = graphql`
  fragment StrapiNavigationLinksFragment on StrapiHeaderNavLink {
    id
    Title
    PrimaryLink {
      Link {
        ...StrapiLinkFragment
      }
    }
    NavLinksSingleColumn
    NavLinks {
      LinkList {
        id
        Title
        HeadingLink {
          Link {
            ...StrapiLinkFragment
          }
        }
        Links {
          Link {
            ...StrapiLinkFragment
          }
        }
        HeadingLinkClickable
      }
    }
    NavLinksMobile {
      LinkList {
        id
        Title
        HeadingLink {
          Link {
            ...StrapiLinkFragment
          }
        }
        Links {
          Link {
            ...StrapiLinkFragment
          }
        }
        HeadingLinkClickable
      }
    }
    LeftSideCategoryBanners {
      NavigationBannerGroup {
        id
        CategoryBanners {
          CategoryBanner {
            Image {
              ...StrapiImageFragment
            }
            CategoryLink {
              Link {
                ...StrapiLinkFragment
              }
            }
          }
        }
        HeadingLink {
          Link {
            ...StrapiLinkFragment
          }
        }
      }
    }
    NavigationFooter {
      BannerButton {
        Link {
          Link {
            ...StrapiLinkFragment
          }
        }
        Text
        Caption
        MaterialIcon
      }
    }
    MobileCategoryImage {
      ...StrapiImageFragment
    }
  }
`

export const StrapiNavigationQuery = graphql`
  query StrapiNavigationQuery {
    strapiHeader {
      id
      HeaderMenu {
        Search {
          AltDescription
          Placeholder
          DefaultValue
        }
        Logo {
          alternativeText
          url
        }
        LogoMobile {
          alternativeText
          url
        }
      }
      NavigationLinks {
        HeaderNavLinks {
          ...StrapiNavigationLinksFragment
        }
      }
      FloridaLinks {
        HeaderNavLinks {
          ...StrapiNavigationLinksFragment
        }
      }
      TexasLinks {
        HeaderNavLinks {
          ...StrapiNavigationLinksFragment
        }
      }
      SouthEastLinks {
        HeaderNavLinks {
          ...StrapiNavigationLinksFragment
        }
      }
      OOMLinks {
        HeaderNavLinks {
          ...StrapiNavigationLinksFragment
        }
      }
    }
  }
`
/**
 * Gets Strapi navigation for the current region
 * @returns {object[]} NavigationLinks array object
 */
const useStaticNavigationQuery = () => {
  const data = useStaticQuery(StrapiNavigationQuery)

  const navigation = useMemo(() => {
    const location = getCurrentLocation()
    return selectRegionBasedNavigation(location.region, data?.strapiHeader)
  }, [data])

  return navigation
}

export default useStaticNavigationQuery
