import React from 'react'
import { func, object } from 'prop-types'
import { getStandardTime, weekdays } from '@helpers/string-helper'
import { addToDataLayer_nextgen } from '@helpers/google-tag-manager'
import { styled, Stack, Link, Box, Typography, Paper } from '@mui/material'
import StarIcon from '@mui/icons-material/StarOutlined'

const StyledHeaderTitle = styled(Typography)(({ theme }) => ({
  fontSize: '11px',
  fontStyle: 'normal',
  fontWeight: '400',
  lineHeight: 'normal',
  textYransform: 'uppercase',
  color: theme.palette.secondary.lighter,
}))

const StyledStoreName = styled('div')`
  margin-bottom: 8px;
  color: #0053a0;
  font-weight: 600;
  line-height: 22pxm;
  display: flex;
  flex-direction: row;
  align-items: baseline;
  justify-content: space-between;
  gap: 8px;
  a {
    font-size: 16px;
    text-decoration-line: none;
  }

  svg {
    font-size: 14px;
    vertical-align: text-top;
  }
`

const StoreHeader = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: center;
`

const StyledAddress = styled('p')`
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  margin-bottom: 3px;
`

const StyledPhone = styled(Link)(({ theme }) => ({
  fontSize: '14px',
  lineHeight: '19px',
  marginBottom: '.4rem',
  textDecoration: 'none',
  color: theme.palette.secondary.dark,
}))

const StyledStoreHours = styled('p')`
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  color: #00854a;
`

const StyledAction = styled(Link)`
  font-size: 12px !important;
  text-decoration-line: underline !important;
  color: #0053a0;
  font-weight: 400;
`

const StyledPaper = styled(Paper)({
  border: '1px solid rgba(0, 0, 0, 0.12) !important',
})

const StyledActionsContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  marginTop: '8px',
  '&>a': {
    marginRight: '10px',
    '&:not(:first-of-type)': {
      padding: '0 10px',
      borderLeft: `1px solid ${theme.palette.divider}`,
    },
  },
}))

const StyledStarIcon = styled(StarIcon)({
  fontSize: '18px!important',
})

const StyledExtendedStoreName = styled('span')({
  textDecorationLine: 'underline',
})

const StyledRatings = styled('span')({
  textDecorationLine: 'none',
  marginLeft: '4px',
})

const StoreChange = ({ myStore, onChangeStore }) => {
  const { address1, address2, storeCity, storeZip, storeState, storePhoneNumber, distance, storeUrl, auto } = myStore

  const getTodayOpen = store => {
    const currentDay = weekdays[new Date().getDay()]
    const storeOpen = store.storeHours?.[`${currentDay.toLowerCase()}Open`]
    const storeClose = store.storeHours?.[`${currentDay.toLowerCase()}Closed`]
    if (storeOpen && storeClose) {
      return (
        <StyledStoreHours variant="body1" isOpen={storeOpen}>
          {storeOpen ? ` Open today ${getStandardTime(storeOpen)} to ${getStandardTime(storeClose)}` : 'Closed today'}
        </StyledStoreHours>
      )
    }
    return null
  }

  const onClickChangeStore = e => {
    e.preventDefault()
    addToDataLayer_nextgen('your_store_widget', {
      stores_action: 'change store location',
    })
    onChangeStore()
  }

  const changeStore = () => (
    <StyledPaper elevation={0} sx={{ marginTop: '1rem', padding: '1rem' }}>
      <StyledHeaderTitle>YOUR CLOSEST STORE</StyledHeaderTitle>
      <StyledStoreName>
        <Link
          href={storeUrl}
          onClick={() => {
            addToDataLayer_nextgen('your_store_widget', {
              stores_action: 'store link',
            })
          }}
        >
          <StyledExtendedStoreName>{myStore?.storeExtendedLocationAndName}</StyledExtendedStoreName>
          {myStore?.storeRatings?.lifetime?.overallScore && (
            <StyledRatings>
              ({myStore?.storeRatings?.lifetime?.overallScore}
              <StyledStarIcon />)
            </StyledRatings>
          )}
        </Link>
        <StyledAction href="/store-locator" underline="hover" onClick={onClickChangeStore}>
          Map
        </StyledAction>
      </StyledStoreName>
      {address1 && storeCity && storeZip ? (
        <StyledAddress>
          {address1}
          {address2 ? `, ${address2}` : ''}
          <br />
          {storeCity}, {storeState} {storeZip}
        </StyledAddress>
      ) : (
        <StyledAddress>{address1}</StyledAddress>
      )}
      <StyledPhone
        href={storePhoneNumber}
        onClick={() => {
          addToDataLayer_nextgen('your_store_widget', {
            stores_action: 'call store',
          })
        }}
        underline="hover"
      >
        {storePhoneNumber}
      </StyledPhone>
      {getTodayOpen(myStore)}
      <StyledActionsContainer>
        <StyledAction
          href="/stores"
          underline="hover"
          onClick={() => {
            addToDataLayer_nextgen('your_store_widget', {
              stores_action: 'view all stores',
            })
          }}
        >
          View All Stores
        </StyledAction>
      </StyledActionsContainer>
    </StyledPaper>
  )

  return myStore?.storeNumber ? <Stack>{changeStore()}</Stack> : null
}

StoreChange.propTypes = {
  myStore: object,
  onChangeStore: func,
}

export default StoreChange
