// dependencies
import React, { useMemo, Fragment, useState, useCallback, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import { Box, useMediaQuery, styled } from '@mui/material'
import Helmet from 'react-helmet'
import { pageTheme } from '@templates/constants'

// components
import Link from './Link'

const StyledImageWrapper = styled(Box, {
  shouldForwardProp: prop => !['ratio', 'height', 'bgColor', 'auto', 'objectFit', 'noPaddingBottom'].includes(prop),
})(({ ratio, height, bgColor, auto, objectFit, noPaddingBottom }) => ({
  display: 'flex',
  width: '100%',
  height: height ? `${height}px` : '100%',
  overflow: 'hidden',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'relative',
  ...(height || noPaddingBottom ? {} : { paddingBottom: `${ratio}%` }),
  '& > picture > img': {
    ...(bgColor
      ? { background: bgColor }
      : {
          background: 'linear-gradient(-45deg, #E5E4E2, #C0C0C0, #D3D3D3, #DEDEDE)',
          backgroundSize: '400% 400%',
          animation: 'gradient 1s ease infinite',
          '@keyframes gradient': {
            '0%': {
              backgroundPosition: '0% 50%',
            },
            '50%': {
              backgroundPosition: '100% 50%',
            },
            '100%': {
              backgroundPosition: '0% 50%',
            },
          },
        }),
    ...(auto
      ? {
          width: 'auto',
          height: 'auto',
          maxWidth: '100%',
          objectFit: objectFit || 'cover',
        }
      : {
          width: '100%',
          height: '100%',
          objectFit: objectFit || 'cover',
        }),
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    width: '100%',
    height: '100%',
    display: 'initial',
  },
}))

// The rtg image api uses f=webp or fm=webp, Safari only support fm=webp - Not applicable for now
// For now the rtg image api uses f=jpeg or fm=jpeg, Safari only support fm=jpeg
const getSrcSets = imageURL => {
  const JPEGimageURL = imageURL
  return JPEGimageURL
    ? [
        {
          srcSet: `${JPEGimageURL}&fm=webp`,
          type: `image/webp`,
        },
        {
          srcSet: JPEGimageURL,
        },
      ]
    : null
}
const getPreloadImageData = ({ srcSet, media, type = null, fetchpriority = 'low' }) => [
  {
    ...(media ? { media } : {}),
    ...(type
      ? {
          href: `${srcSet}&fm=webp`,
          type,
        }
      : {
          href: srcSet,
        }),
    rel: 'preload',
    as: 'image',
    fetchpriority,
  },
]

const calculateRatio = (height, width) => {
  const ratio = (height / width) * 100
  return Math.round((ratio + Number.EPSILON) * 100) / 100
}

const StrapiImage = ({
  data = {},
  link = null,
  auto = false,
  className = '',
  wrapperClassName = '',
  trackingData = null,
  loading,
  height,
  noPaddingBottom,
  initBgColor,
}) => {
  const isMobile = useMediaQuery('(max-width: 639px)')

  const fetchpriority = data?.title?.toLowerCase().includes?.('hero') ? 'high' : 'low'
  const fallbackImage = data?.mobile && isMobile ? data?.mobile : data?.desktop || {}
  const mobileSources = useMemo(() => getSrcSets(data?.mobile?.url), [data])
  const desktopSources = useMemo(() => getSrcSets(data?.desktop?.url), [data])
  const [bgColor, setBgColor] = useState(initBgColor)

  const wrapperProps = {
    ratio: calculateRatio(fallbackImage.height || 550, fallbackImage.width || 800),
    height,
    noPaddingBottom,
    objectFit: data?.imageSizing,
    auto,
    bgColor: bgColor ?? initBgColor,
    ...(link ? { component: Link, data: link, trackingData } : {}),
  }

  const imgProps = {
    loading,
    className,
    fetchpriority,
    src: fallbackImage?.url,
  }
  if (!fallbackImage?.alternativeText && !data?.alt) {
    imgProps.role = 'presentation'
    imgProps['aria-hidden'] = 'true'
  }

  return fallbackImage?.url ? (
    <StyledImageWrapper {...wrapperProps} className={wrapperClassName}>
      <picture>
        {fetchpriority === 'high' && (
          <>
            {mobileSources &&
              mobileSources.map(source => {
                const sourceProps = { ...source, media: '(max-width: 639px)' }
                return (
                  <Fragment key={`${source.srcSet}-mobile`}>
                    <source {...sourceProps} />
                    <Helmet link={getPreloadImageData({ ...sourceProps, fetchpriority })} />
                  </Fragment>
                )
              })}
            {desktopSources &&
              desktopSources.map(source => {
                const media = mobileSources ? '(min-width: 640px)' : null
                const sourceProps = { ...source, ...(media ? { media } : {}) }
                return (
                  <Fragment key={`${source.srcSet}-desktop`}>
                    <source {...sourceProps} />
                    <Helmet link={getPreloadImageData({ ...sourceProps, fetchpriority })} />
                  </Fragment>
                )
              })}
          </>
        )}
        {/* Fallback */}
        <img
          {...imgProps}
          alt={fallbackImage?.alternativeText || data?.alt || ''}
          onLoad={() => setBgColor('transparent')}
        />
      </picture>
    </StyledImageWrapper>
  ) : null
}

StrapiImage.propTypes = {
  data: PropTypes.shape({
    desktop: PropTypes.object,
    mobile: PropTypes.object,
    imageSizing: PropTypes.string,
    title: PropTypes.string,
    alt: PropTypes.string,
    height: PropTypes.number,
    width: PropTypes.number,
  }).isRequired,
  link: PropTypes.object,
  trackingData: PropTypes.object,
  loading: PropTypes.oneOf(['eager', 'lazy']),
  auto: PropTypes.bool,
  className: PropTypes.string,
  wrapperClassName: PropTypes.string,
  height: PropTypes.number,
  noPaddingBottom: PropTypes.bool,
  initBgColor: PropTypes.string,
}

export default StrapiImage

export const StrapiImageFragment = graphql`
  fragment StrapiImageFragment on STRAPI__MEDIA {
    url
    alternativeText
    id
    mime
    height
    width
  }
`
