import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { colors, breakPoints } from '@constants/styles'
import { connectAutoComplete } from 'react-instantsearch-dom'
import useDebounce from '@helpers/hooks/useDebounce'
import { arrayOf, object, func, string, bool, number, any } from 'prop-types'
import ConnectedSuggestion from './ConnectedSuggestion'

const SuggestionList = styled.ul`
  &&& {
    background: ${colors.white};
    border-bottom: 1px solid ${colors.lightGrey};
    flex-direction: column;
    //max-width: 104rem;
    //min-width: 370px;
    margin: 1px;
    padding: 2px 20px 10px 6px;
    width: 100%;

    @media screen and (max-width: ${breakPoints['large-max']}) {
      margin: 0 10px;
    }

    @media screen and (max-width: ${breakPoints.small}) {
      width: 100%;
      padding: 2px 15px;
      margin: 1px;
    }

    .selected {
      color: ${colors.disabled};
    }
  }
`

const NoResultsSuggestion = styled.li`
  &&& {
    color: ${colors.darkGrey};

    span {
      font-weight: 600;
    }
  }
`

const SuggestionListWrapper = styled.section`
  left: 0;
  margin: auto;
  position: absolute;
  right: 0;
  top: 48px;

  @media screen and (max-width: ${breakPoints['large-max']}) {
    top: 58px;
  }
`

export const SuggestionsList = ({
  hits = [],
  query,
  refine,
  onFormSubmit,
  showSuggestions,
  tabIndex,
  suggestionRef,
  searchBoxRef,
  mobileHitsCount,
  contentTypeSearch,
}) => {
  const [currentSelection, setCurrentSelection] = useState('')
  const debouncedQuery = useDebounce(query, 250)
  const isStoreSearch = contentTypeSearch === 'store'

  useEffect(() => {
    if (debouncedQuery && debouncedQuery.length > 1) {
      // blocks use of sku in autocomplete
      if (debouncedQuery.length > 3 && Number(debouncedQuery.replace('P', ''))) {
        return
      }
      refine(debouncedQuery)
    }
  }, [debouncedQuery, refine])

  useEffect(() => {
    if (hits.length && !currentSelection) {
      setCurrentSelection(0)
    }
    if (currentSelection > hits.length) {
      setCurrentSelection(hits.length ? 0 : '')
    }
  }, [currentSelection, hits.length, setCurrentSelection])

  const suggestionsKeyEvent = async (event, isList = false) => {
    if (event.keyCode === 40) {
      event.preventDefault()
      if (hits.length === currentSelection + 1) {
        setFocus(0)
        return setCurrentSelection(0)
      }
      setFocus(currentSelection + 1)

      return setCurrentSelection(currentSelection + 1)
    }
    if (event.keyCode === 38) {
      event.preventDefault()
      if (currentSelection === 0) {
        searchBoxRef.current.focus()
      }
      setFocus(currentSelection - 1)
      return currentSelection && setCurrentSelection(currentSelection - 1)
    }
    if (event.keyCode === 13 && !isList) {
      const suggestion = hits[currentSelection].query
      const index = currentSelection
      return onFormSubmit(event, true, suggestion, index)
    }
    return null
  }

  const setFocus = index => {
    if (window && typeof window !== 'undefined') {
      const element = document.getElementById(`searchSuggest${index}`)

      if (element) {
        element.focus()
      }
    }
  }

  const getNoSuggestionContent = () => {
    if (isStoreSearch)
      return (
        <>
          Search stores based on ZIP Code <span>"{query}"</span>
        </>
      )
    return (
      <>
        Sorry, No results found for <span>"{query}"</span>
      </>
    )
  }

  return (
    <>
      {query?.length > 1 && hits?.length > 0 && showSuggestions && !isStoreSearch && (
        <SuggestionListWrapper>
          <SuggestionList
            suggestions={hits}
            role="listbox"
            aria-activedescendant={`searchSuggest${currentSelection}`}
            tabIndex={tabIndex}
            onKeyDown={event => suggestionsKeyEvent(event, true)}
          >
            {hits.map((suggestion, index) => (
              <ConnectedSuggestion
                key={suggestion.objectID}
                suggestion={suggestion}
                onFormSubmit={onFormSubmit}
                index={index}
                selected={index === currentSelection}
                suggestionsKeyEvent={suggestionsKeyEvent}
                suggestionRef={suggestionRef}
                mobileHitsCount={mobileHitsCount}
              />
            ))}
          </SuggestionList>
        </SuggestionListWrapper>
      )}
      {query?.length > 1 && (hits?.length === 0 || isStoreSearch) && showSuggestions && (
        <SuggestionListWrapper>
          <SuggestionList
            suggestions={hits}
            role="listbox"
            aria-activedescendant={`searchSuggest${currentSelection}`}
            tabIndex={tabIndex}
            onKeyDown={event => suggestionsKeyEvent(event, true)}
          >
            <NoResultsSuggestion>{getNoSuggestionContent(contentTypeSearch)}</NoResultsSuggestion>
          </SuggestionList>
        </SuggestionListWrapper>
      )}
    </>
  )
}

SuggestionsList.propTypes = {
  hits: arrayOf(object),
  query: string,
  refine: func,
  onFormSubmit: func,
  showSuggestions: bool,
  tabIndex: number,
  suggestionRef: any,
  searchBoxRef: any,
  mobileHitsCount: any,
  contentTypeSearch: string,
}

export const ConnectedSuggestionList = connectAutoComplete(SuggestionsList)
